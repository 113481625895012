import { ReactNode } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  banner: string;
  height?: number | string;
}

export const Banner = function Component(props: IProps) {
  const BannerContainer = styled.div`
    width: 100%;
    height: ${props.height}px;
    background: url(${props.banner}) no-repeat;
    background-size: cover;
  `;

  return (
    <BannerContainer className={classnames([`relative w-full flex justify-center h-${props.height}`, props.className])}>
      {props.children}
    </BannerContainer>
  );
};