import { Flex } from 'antd';
import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { throttle } from '@/utils';
import './Navigation.scss';

export default function Navigation() {
  const location = useLocation();

  const handleScroll = () => {
    const navigation = document.querySelector('.header-navigation');
    const navigationHight = navigation?.getBoundingClientRect()?.height || 0;
    const pageYOffset = window.scrollY || window.pageYOffset || 0;
    
    if (pageYOffset >= navigationHight) {
      //  @ts-ignore
      navigation.classList.add('white');
    } else {
      // @ts-ignore
      navigation.classList.remove('white');
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [location.pathname]);

  useEffect(()=> {
    document.addEventListener('scroll', throttle(()=> {
      handleScroll();
    }, 100));
  }, []);

  return (
    <Flex className="header-navigation h-60 w-full px-60 box-border sticky top-0 z-[100]" justify='center'>
      <Flex className='w-[1120px] h-60 text-text-2' align="center" gap={54}>
        <div className='header-logo' />
        <NavLink className='navigation-item' to="/">首頁</NavLink>
        <NavLink className='navigation-item' to="/partner">ARM 戰略合作夥伴</NavLink>
        <a className='navigation-item' href="https://mcisaas.com/mcc-open/" target="_blank" rel="noreferrer">滴灌通開放平台</a>
        {/* <div className="ml-auto">This webpage is available only in Chinese.</div>
         */}
        <div className="page-desc ml-auto">THIS WEBPAGE IS AVAILABLE ONLY IN CHINESE.</div>
      </Flex>
    </Flex>
  );
}
