export var THEME_COLOR = {
    'brand-1': '#EDFAF8',
    'brand-2': '#C9F0E9',
    'brand-3': '#9BE0D5',
    'brand-4': '#72D4C3',
    'brand-5': '#45BFB0',
    'brand-6': '#23BFAC',
    'brand-7': '#1F8F82',
    'brand-8': '#128073',
    'brand-9': '#04665B',
    'brand-10': '#004D44',
    'text-1': '#FFFFFF',
    'text-2': '#C9CDD4',
    'text-3': '#86909C',
    'text-4': '#4E5969',
    'text-5': '#1D2129',
    'line-1': '#F2F3F5',
    'line-2': '#E5E6EB',
    'line-3': '#C9CDD4',
    'line-4': '#86909C',
    'fill-1': '#FFFFFF',
    'fill-2': '#F7F8FA',
    'fill-3': '#F2F3F5',
    'fill-4': '#E5E6EB',
    'fill-5': '#C9CDD4',
    'fill-6': '#4E5969',
    'fill-7': '#1D2129',
    'danger-1': '#fff2f2',
    'danger-2': '#ffd6d8',
    'danger-3': '#ffb5b8',
    'danger-4': '#ff9195',
    'danger-5': '#fb6e77',
    'danger-6': '#e35661',
    'danger-7': '#c9384a',
    'danger-8': '#b01c37',
    'danger-9': '#960627',
    'danger-10': '#730524',
    'blue-1': '#abcaff',
    'blue-2': '#8cb8ff',
    'blue-3': '#69a1ff',
    'blue-4': '#478dff',
    'blue-5': '#2174ff',
    'blue-6': '#3491FA',
    'blue-7': '#084dcd',
    'blue-8': '#073ab5',
    'blue-9': '#062e9a',
    'blue-10': '#1e2c60',
    'nBlue-1': '#e8f7ff',
    'nBlue-2': '#ccd5ff',
    'nBlue-3': '#aebaf2',
    'nBlue-4': '#93a1e6',
    'nBlue-5': '#657ae6',
    'nBlue-6': '#495fcc',
    'nBlue-7': '#4053b3',
    'nBlue-8': '#374799',
    'nBlue-9': '#2c397a',
    'nBlue-10': '#1d2652',
    'warning-1': '#fff4e5',
    'warning-2': '#ffd8ad',
    'warning-3': '#ffb97d',
    'warning-4': '#ff9852',
    'warning-5': '#ed8139',
    'warning-6': '#d66724',
    'warning-7': '#c25110',
    'warning-8': '#a24006',
    'warning-9': '#873105',
    'warning-10': '#692204',
    'green-1': '#E8FFEA',
    'green-2': '#AFF0B5',
    'green-3': '#7BE188',
    'green-4': '#4CD263',
    'green-5': '#23C343',
    'green-6': '#00B42A',
    'green-7': '#009A29',
    'green-8': '#008026',
    'green-9': '#006622',
    'green-10': '#004D1C',
    'lime-1': '#fdffee',
    'lime-2': '#eef8c2',
    'lime-3': '#def198',
    'lime-4': '#cbe970',
    'lime-5': '#b8e24b',
    'lime-6': '#a8db39',
    'lime-7': '#84b723',
    'lime-8': '#629412',
    'lime-9': '#447006',
    'lime-10': '#2a4d00',
    'yellow-1': '#fefff0',
    'yellow-2': '#fefec6',
    'yellow-3': '#fdfa9d',
    'yellow-4': '#fcf374',
    'yellow-5': '#fbe94b',
    'yellow-6': '#fae13c',
    'yellow-7': '#cfb325',
    'yellow-8': '#a38614',
    'yellow-9': '#785e07',
    'yellow-10': '#4d3800',
    'gold-1': '#fffce8',
    'gold-2': '#fdf4be',
    'gold-3': '#fce995',
    'gold-4': '#fadc6c',
    'gold-5': '#f9cc44',
    'gold-6': '#f7c034',
    'gold-7': '#cc961f',
    'gold-8': '#A26F0F',
    'gold-9': '#774b04',
    'gold-10': '#4d2d00',
    'overlay-1': 'rgba(0, 0, 0, 0.24)',
    'overlay-2': 'rgba(0, 0, 0, 0.32)',
    'overlay-3': 'rgba(0, 0, 0, 0.48)',
    'overlay-4': 'rgba(0, 0, 0, 0.64)',
    'overlay-5': 'rgba(0, 0, 0, 0.8)',
    'purple-1': '#F5E8FF',
    'purple-2': '#DDBEF6',
    'purple-3': '#C396ED',
    'purple-4': '#A871E3',
    'purple-5': '#8D4EDA',
    'purple-6': '#722ED1',
    'purple-7': '#551DB0',
    'purple-8': '#3C108F',
    'purple-9': '#27066E',
    'purple-10': '#16004D',
    'magenta-1': '#FFE8F1',
    'magenta-2': '#FDC2DB',
    'magenta-3': '#FB9DC7',
    'magenta-4': '#F979B7',
    'magenta-5': '#F754A8',
    'magenta-6': '#E5463B',
    'magenta-7': '#CB1E83',
    'magenta-8': '#A11069',
    'magenta-9': '#77064F',
    'magenta-10': '#4D0034'
};
export var ANTD_THEME = {
    colorPrimary: '#00ac97',
    colorInfo: '#00ac97',
    colorPrimaryBg: '#e5fffb',
    colorPrimaryBgHover: '#c2fff5',
    colorPrimaryBorder: '#00ac97',
    colorPrimaryBorderHover: '#25ccb8',
    colorPrimaryHover: '#25ccb8',
    colorPrimaryActive: '#008f7e',
    colorPrimaryTextHover: '#25ccb8',
    colorPrimaryText: '#00ac97',
    colorPrimaryTextActive: '#008f7e',
    colorErrorBg: '#fdecee',
    colorErrorBgHover: '#f9d7d9',
    colorErrorBorder: '#e34d59',
    colorErrorBorderHover: '#f36d78',
    colorErrorHover: '#e35661',
    colorError: '#e34d59',
    colorErrorActive: '#b01c37',
    colorErrorTextHover: '#f36d78',
    colorErrorText: '#e34d59',
    colorErrorTextActive: '#c9353f',
    colorWarningBg: '#fef3e6',
    colorWarningBgHover: '#f9e0c7',
    colorWarningBorder: '#ed7b2f',
    colorWarningBorderHover: '#f2995f',
    colorWarningHover: '#d35a21',
    colorWarning: '#ed7b2f',
    colorWarningActive: '#ba431b',
    colorWarningTextHover: '#f2995f',
    colorWarningText: '#ed7b2f',
    colorWarningTextActive: '#d35a21',
    colorSuccessBg: '#e5fffb',
    colorSuccessBgHover: '#c2fff5',
    colorSuccessBorder: '#00ac97',
    colorSuccessBorderHover: '#25ccb8',
    colorSuccessHover: '#25ccb8',
    colorSuccess: '#00ac97',
    colorSuccessActive: '#008f7e',
    colorSuccessTextHover: '#25ccb8',
    colorSuccessText: '#00ac97',
    colorSuccessTextActive: '#008f7e',
    colorLink: '#00ac97',
    colorTextBase: '#1d2129',
    borderRadius: 4,
    colorText: '#1d2129',
    colorTextSecondary: '#4e5969',
    colorTextTertiary: '#86909c',
    colorTextQuaternary: '#c9cdd4',
    colorBorder: '#c9cdd4',
    colorBorderSecondary: '#e5e6eb',
    colorFill: '#ffffff',
    colorFillSecondary: '#f7f8fa',
    colorFillTertiary: '#f2f3f5',
    colorFillQuaternary: '#e5e6eb',
    colorBgMask: '#1d2129a3',
    colorBgLayout: '#ffffff',
    colorBgElevated: '#f7f8fa',
    colorBgContainer: '#ffffff',
    fontSizeHeading2: 28,
    fontSizeHeading1: 32,
    borderRadiusXS: 2,
    borderRadiusLG: 8,
    wireframe: false,
    colorBgSpotlight: '#4e5969'
};
