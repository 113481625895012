import { Skeleton } from 'antd';
import styled from '@emotion/styled';

const SearchFormSkeleton = styled(Skeleton)`
  h3.ant-skeleton-title {
    display: none !important;
  }
  ul.ant-skeleton-paragraph > li {
    height: 200px !important;
  }
`;

const TableSkeleton = styled(Skeleton)`
  h3.ant-skeleton-title {
    display: none !important;
  }
  ul.ant-skeleton-paragraph > li {
    height: 50px !important;
  }
`;

export default function PageSkeleton() {
  return (
    <>
      <SearchFormSkeleton active paragraph={{ rows: 1, width: ['100%'] }} />
      <TableSkeleton active paragraph={{ rows: 10, width: new Array(10).fill('100%') }} />
    </>
  );
}
