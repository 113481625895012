import { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import {Spin, Flex} from "antd";

export default forwardRef(function GlobalLoadingModal(props, ref) {
  const [isOpen, setIsOpen] = useState(false);
  const [requestMap, setRequestMap] = useState({});
  const [loadintHintText, setLoadintHintText] = useState("");

  useImperativeHandle(ref, () => {
    return {
      request2Show: (apiUrl: string, hintText?: string) => {
        setRequestMap({
          ...requestMap,
          [apiUrl]: true,
        });
        if (!!hintText) {
          setLoadintHintText(hintText);
        }
      },
      request2Hide: (apiUrl: string) => {
        setRequestMap({
          ...requestMap,
          [apiUrl]: false,
        });
      },
    };
  });

  useEffect(
    function () {
      const entries = Object.entries(requestMap);
      const loadingFlags = entries.map(([apiUrl, isLoading]) => isLoading);
      const isGlogalLoading = loadingFlags.some((loading) => loading === true);
      if (isGlogalLoading) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
        setLoadintHintText("");
      }
    },
    [requestMap]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Flex className="h-screen w-screen fixed left-[0] top-[0]" align='center' justify="center">
      <Spin  />
      {loadintHintText ? loadintHintText : undefined}
    </Flex>
  );
});
