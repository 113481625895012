import PageSkeleton from '@/components/PageSkeleton';
import { routeMap } from '@/config/menu';
import '@/styles/global.css';
import { generatePath2RouteComponentMap } from '@/utils';
import { ConfigProvider } from 'antd';
import React, { Suspense } from 'react';
import { Outlet, Route, Routes, Navigate } from 'react-router-dom';
import Footer from './Footer';
import Navigation from './Navigation';

const Layout: React.FC = () => {
  return (
    <ConfigProvider>
      <Navigation />
      <Routes>
        <Route path="/workbench/arm-official-website" element={<Navigate to="/" />} />
        <Route path="/" element={<Outlet />}>
          {generatePath2RouteComponentMap(routeMap).map(([path, component]) => {
            return (
              <Route
                key={path}
                index={path === '/'}
                path={path}
                element={<Suspense fallback={<PageSkeleton />}>{component}</Suspense>}
              />
            );
          })}
          <Route path="*" element={'not fount'} />
        </Route>
      </Routes>
      <Footer />
    </ConfigProvider>
  );
};

export default Layout;
