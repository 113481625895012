import { PathComponentMap } from '@/types';
import type { ProLayoutProps } from '@ant-design/pro-components/es';

/**
 * 根据 menuConfig 配置生成路径到组件的映射
 *
 * @returns { [path: string]: React.ReactNode } 路径到组件的映射
 */
export function generatePath2RouteComponentMap(
  menuConfig: ProLayoutProps['route'],
  pathMap: PathComponentMap = {},
  parentPath = '',
) {
  if (!menuConfig) return [];
  const currentPath = menuConfig?.path;
  if (!currentPath) throw new Error('path field is required');

  const fullPathSegment =
    currentPath === '/' ? currentPath : parentPath === '/' ? `/${menuConfig.path}` : `${parentPath}/${currentPath}`;
  if (menuConfig?.path && menuConfig?.component) {
    pathMap[fullPathSegment] = menuConfig.component;
  }

  if (menuConfig?.routes && menuConfig.routes.length > 0) {
    for (const nestedRoute of menuConfig.routes) {
      generatePath2RouteComponentMap(nestedRoute, pathMap, fullPathSegment);
    }
  }

  return Object.entries(pathMap);
}

// 防抖函数
export const debounce = (func: any, timeout = 300) => {
  let timer: any;
  return (...args: any[]) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
};

/**
 *
 * @param func    {IFunction}   实际要执行的函数
 * @param wait    {Number}     执行间隔，单位是毫秒（ms），默认100ms
 * @return        {IFunction}   返回一个“节流”函数
 */

export function throttle<T = Function>(func: Function, wait = 100): T {
  // 利用闭包保存定时器和上次执行时间
  // eslint-disable-next-line
  let timer: any = 0;
  let previous = 0; // 上次执行时间
  // eslint-disable-next-line
  return function (this: any): void {
    // 保存函数调用时的上下文和参数，传递给 fn
    const context = this;
    const args = arguments;
    const now = +new Date();
    if (previous && now < previous + wait) {
      // 周期之中
      clearTimeout(timer);
      timer = setTimeout(function () {
        previous = now;
        func.apply(context, args as any);
      }, wait);
    } else {
      previous = now;
      func.apply(context, args as any);
    }
  } as any;
}
