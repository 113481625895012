import { Welcome, Partner } from '@/pages';
import type { ProLayoutProps } from '@ant-design/pro-components/es';

export const routeMap: ProLayoutProps['route'] = {
  path: '/',
  name: '首页',
  component: <Welcome />,
  routes: [
    {
      path: 'partner',
      name: '战略合作伙伴',
      component: <Partner />,
    }
  ],
};
