import FooterBg from '@/assets/footer.png';
import { Banner } from '@/pages/components/Banner';
import footer1 from '@/assets/footer1.png';
import footer2 from '@/assets/footer2.png';
import footer3 from '@/assets/footer3.png';
import QRCode from '@/assets/home-qrcode.png';

export default function Footer() {
  return (
    <Banner banner={FooterBg} height={320}>
      <div className='w-[1120px] flex relative z-1 text-16 text-fill-1 leading-[24px]'>
        <div className="mt-76 w-[483px]">
          <img src={footer1} alt="" className='w-[100px]' />
          <div className='mt-16'>工作時間：<span className='text-text-2'>9:00-18:00</span></div>
          <div className='my-4'>聯繫郵箱：<span className='text-text-2'>ARM@microconnect.com</span></div>
          {/* <div>聯繫地址：<span className='text-text-2'>廣東省深圳市南山區前海香江金融中心29F</span></div> */}
          <div>聯繫地址：<span className='text-text-2'>澳門蘇亞利斯 博士大馬路澳門財富中心16樓A, J及K座</span></div>
        </div>
        <div className='w-[286px] flex flex-col ml-32 mt-76'>
          <img src={footer2} alt="" className='w-[100px]' />
          <a href="https://mcex.mo/about/?lang=zh-hans" target="_blank" rel="noreferrer" className='mt-20 text-blue-6 !leading-24'>關於滴灌通澳交所</a>
          <a href="https://mt.microconnect.cc/" target="_blank" rel="noreferrer" className='my-4 text-blue-6'>滴灌通澳交所M-Terminal</a>
        </div>
        <div className='flex flex-col ml-32 mt-76'>
          <img src={footer3} alt="" className='w-[100px]' />
          <img src={QRCode} alt="" className='h-80 w-80 mt-20' />
        </div>
      </div>
    </Banner>
  );
}
