import GlobalLoadingModal from '@/components/GlobalLoadingModal';
import App from '@/components/Layout';
import { ANTD_THEME } from '@mci/app-config.theme';
import { App as AntdApp, ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

let root: ReactDOM.Root;
const id = '#arm-official-website';
let node = document.getElementById(id);

if (!node) {
  node = document.createElement('div');
  node.id = id;
  document.body.appendChild(node);
}
root = ReactDOM.createRoot(node);
// const isDev = process.env.NODE_ENV === 'development';

root.render(
  <BrowserRouter basename="/">
    <ConfigProvider
      theme={{
        hashed: false,
        token: ANTD_THEME,
      }}>
      <AntdApp>
        <App />
      </AntdApp>
      <GlobalLoadingModal
        ref={(instance: any) => {
          window.mci_global_loading_modal = instance;
        }}
      />
    </ConfigProvider>
  </BrowserRouter>,
);
